<template lang="pug">
  div(v-if='Inicio')
    //-Móvil
    v-carousel(v-model='valor' :cycle='false' hide-delimiters  height='88vh'  :show-arrows='false').d-md-none
      v-carousel-item(v-for='{texto, imagen, ruta}, i in Inicio.imagenes_movil_carrusel' :key='i')
        v-container(fluid).pa-0 
          
          v-img(  height='100%' :src='imagen' contain )
            v-container
              v-row.mt-12
              v-row.mt-12
              v-row.mt-12
              
                v-col.pa-0(cols=12).justify-end.aling-center    
                  v-container
                    v-row(align='center' justify='center' ).mt-3
                      h3(v-html='texto.línea_1').primario--text.text-center
                    v-row(align='center' justify='center'  ).mt-3
                      h4(v-html='texto.línea_2').white--text.text-center
                    v-row(align='center' justify='center' )
                      div.cursiva
                        p(v-html='texto.línea_3').white--text.white--text.text-center

                  v-container.pa-0.mt-6
                    v-row
                      v-col(align='center' justify='center')
                        v-hover(v-slot="{ hover }")
                          v-btn(small :color='hover ? "secundario" :"primario"' :dark='hover' 
                          :to='{name: ruta, params: {}}').elevation-0.forma_boton
                            h2(v-if='hover' style='font-size: 1.3em').white--text.font-weight-light Descubre 
                            h2(v-else style='font-size: 1.3em').white--text.font-weight-light Descubre 
                            
                    v-row(align='end' justify='center').mt-12
                      v-btn(x-small icon @click='valor=i' v-for='{texto, imagen}, i in Inicio.imágenes' :key='i' ).mr-2.mt-12
                        v-icon(v-html='valor==i ? "fas fa-square " : "far fa-square"'  :color='valor==i ? "primario" : "white"' )
         
    //-PC
    v-carousel(v-model='valor'  hide-delimiters :cycle='false' height='100%' :show-arrows='false').d-none.d-md-block
      v-carousel-item(v-for='{texto, video , imagen, ruta}, i in Inicio.imágenes' :key='i')
        v-container(fluid).pa-0
          //- div( style='position: relative ; height: 600px '  v-if='video' )
          //-   iframe(width='100%' height='600px' :src='`${video}${valor==1?"?rel=0&amp;autoplay=1" : "" }`' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen='')
          //-   v-row( style='position: absolute; bottom: 120px; right: 50px ' align='end' justify='end')
          //-     v-btn(x-small icon @click='valor=i' v-for='{texto, imagen}, i in Inicio.imágenes' :key='i' ).mr-3
          //-       v-icon(v-html='valor==i ? "fas fa-square " : "far fa-square"'  :color='valor==i ? "primario" : "white"' )
          v-img( :src='imagen' )
            v-container.mr-12
              v-row.mt-12
              v-row.mt-12
              v-row.mt-12
              v-row.mt-12
              v-row.mt-12
                v-col.pa-0(cols=12).pr-5
                  v-container
                    v-row(align='end' justify='end' style='line-height : 40px; font-size: 1.15em')
                      div.cursiva
                        h1(v-html='texto.línea_1').white--text
                    v-row(align='end' justify='end' style='line-height : 40px; font-size: 1.15em').mt-9
                      div.cursiva   
                        h2(v-html='texto.línea_2').white--text
                    v-row(align='end' justify='end' style='line-height : 30px; font-size: 1.27em').mt-7
                      div.cursiva
                        h2(v-html='texto.línea_3').white--text.font-weight-light
                  v-container.pa-0.mt-3
                    v-row
                      v-col(align='end' justify='end')
                        v-hover(v-slot="{ hover }")
                          v-btn(x-large :color='hover ? "secundario" :"primario"' :dark='hover' 
                          :to='{name: ruta, params: {}}').elevation-0.forma_boton
                            h2(v-if='hover' style='font-size: 1.3em').white--text.font-weight-light Descubre 
                            h2(v-else style='font-size: 1.3em').white--text.font-weight-light Descubre 

                    v-row(align='end' justify='end').mt-8
                      v-btn(x-small icon @click='valor=i' v-for='{texto, imagen}, i in Inicio.imágenes' :key='i' ).mr-3
                        v-icon(v-html='valor==i ? "fas fa-square " : "far fa-square"'  :color='valor==i ? "primario" : "white"' )
    
            
</template>
<script>
export default {
  data: ()=>({
    valor: 0,
  }),
}
</script>
<style lang="sass">
    .forma_boton
      border-radius: 0px!important
      width: 175px!important
      height: 48px!important
</style>
