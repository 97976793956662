var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Inicio)?_c('div',[_c('v-carousel',{staticClass:"d-md-none",attrs:{"cycle":false,"hide-delimiters":"","height":"88vh","show-arrows":false},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}},_vm._l((_vm.Inicio.imagenes_movil_carrusel),function(ref,i){
var texto = ref.texto;
var imagen = ref.imagen;
var ruta = ref.ruta;
return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"height":"100%","src":imagen,"contain":""}},[_c('v-container',[_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pa-0 justify-end aling-center",attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',{staticClass:"mt-3",attrs:{"align":"center","justify":"center"}},[_c('h3',{staticClass:"primario--text text-center",domProps:{"innerHTML":_vm._s(texto.línea_1)}})]),_c('v-row',{staticClass:"mt-3",attrs:{"align":"center","justify":"center"}},[_c('h4',{staticClass:"white--text text-center",domProps:{"innerHTML":_vm._s(texto.línea_2)}})]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"cursiva"},[_c('p',{staticClass:"white--text white--text text-center",domProps:{"innerHTML":_vm._s(texto.línea_3)}})])])],1),_c('v-container',{staticClass:"pa-0 mt-6"},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"elevation-0 forma_boton",attrs:{"small":"","color":hover ? "secundario" :"primario","dark":hover,"to":{name: ruta, params: {}}}},[(hover)?_c('h2',{staticClass:"white--text font-weight-light",staticStyle:{"font-size":"1.3em"}},[_vm._v("Descubre ")]):_c('h2',{staticClass:"white--text font-weight-light",staticStyle:{"font-size":"1.3em"}},[_vm._v("Descubre ")])])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-12",attrs:{"align":"end","justify":"center"}},_vm._l((_vm.Inicio.imágenes),function(ref,i){
var texto = ref.texto;
var imagen = ref.imagen;
return _c('v-btn',{key:i,staticClass:"mr-2 mt-12",attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.valor=i}}},[_c('v-icon',{attrs:{"color":_vm.valor==i ? "primario" : "white"},domProps:{"innerHTML":_vm._s(_vm.valor==i ? "fas fa-square " : "far fa-square")}})],1)}),1)],1)],1)],1)],1)],1)],1)],1)}),1),_c('v-carousel',{staticClass:"d-none d-md-block",attrs:{"hide-delimiters":"","cycle":false,"height":"100%","show-arrows":false},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}},_vm._l((_vm.Inicio.imágenes),function(ref,i){
var texto = ref.texto;
var video = ref.video;
var imagen = ref.imagen;
var ruta = ref.ruta;
return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"src":imagen}},[_c('v-container',{staticClass:"mr-12"},[_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"}),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pa-0 pr-5",attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',{staticStyle:{"line-height":"40px","font-size":"1.15em"},attrs:{"align":"end","justify":"end"}},[_c('div',{staticClass:"cursiva"},[_c('h1',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(texto.línea_1)}})])]),_c('v-row',{staticClass:"mt-9",staticStyle:{"line-height":"40px","font-size":"1.15em"},attrs:{"align":"end","justify":"end"}},[_c('div',{staticClass:"cursiva"},[_c('h2',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(texto.línea_2)}})])]),_c('v-row',{staticClass:"mt-7",staticStyle:{"line-height":"30px","font-size":"1.27em"},attrs:{"align":"end","justify":"end"}},[_c('div',{staticClass:"cursiva"},[_c('h2',{staticClass:"white--text font-weight-light",domProps:{"innerHTML":_vm._s(texto.línea_3)}})])])],1),_c('v-container',{staticClass:"pa-0 mt-3"},[_c('v-row',[_c('v-col',{attrs:{"align":"end","justify":"end"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"elevation-0 forma_boton",attrs:{"x-large":"","color":hover ? "secundario" :"primario","dark":hover,"to":{name: ruta, params: {}}}},[(hover)?_c('h2',{staticClass:"white--text font-weight-light",staticStyle:{"font-size":"1.3em"}},[_vm._v("Descubre ")]):_c('h2',{staticClass:"white--text font-weight-light",staticStyle:{"font-size":"1.3em"}},[_vm._v("Descubre ")])])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-8",attrs:{"align":"end","justify":"end"}},_vm._l((_vm.Inicio.imágenes),function(ref,i){
var texto = ref.texto;
var imagen = ref.imagen;
return _c('v-btn',{key:i,staticClass:"mr-3",attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.valor=i}}},[_c('v-icon',{attrs:{"color":_vm.valor==i ? "primario" : "white"},domProps:{"innerHTML":_vm._s(_vm.valor==i ? "fas fa-square " : "far fa-square")}})],1)}),1)],1)],1)],1)],1)],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }